<template>
  <section
    id="solar-robots"
  >
    <base-section-heading title="RH SOLAR PANEL CLEANING ROBOTS">
    </base-section-heading>
    <base-right-photo
      :contain="true"
      :src="require('@/assets/RH108 image.jpg')"
      subheading="RH-108 Integrated Cleaning Robot"
      html="<ul>
  <li><strong>Fully intelligent, automated, unattended cleaning</strong></li>
  <li><strong>High cleaning efficiency</strong></li>
  <li><strong>Small turning radius, no dead spot</strong></li>
  <li><strong>Waterproof, dustproof, anti-fog (high grade)</strong></li>
  <li><strong>Mobile app / web software, remote real-time viewing of running status</strong></li>
  <li><strong>Supports customization & high scalability</strong></li>
  <li><strong>Dimension: 1200(mm) x 700(mm) x 240(mm)</strong></li>
</ul>"
      color="black--text"
    >
    </base-right-photo>
    <base-right-photo
      :contain="true"
      :src="require('@/assets/RH308 image.jpg')"
      subheading="RH-308 Intelligent Shuttle Cleaning Robot"
      html="<ul>
  <li><strong>Runs on rails, high stability</strong></li>
  <li><strong>Cleaning components of large & medium sized photovoltaic power stations</strong></li>
  <li><strong>Adopts waterless cleaning technology</strong></li>
  <li><strong>Manual/Auto cleaning mode *Remote control possible over wireless network</strong></li>
  <li><strong>High speed rotation roller brush</strong></li>
  <li><strong>Self-powered without external power supply</strong></li>
  <li><strong>Dimension: 600(mm) x 450(mm)</strong></li>
  <li><strong>Weight: 45kg</strong></li>
</ul>"
      color="black--text"
    >
    </base-right-photo>
    <base-right-photo
      :contain="true"
      :src="require('@/assets/RH508 image.jpg')"
      subheading="RH-508 Intelligent Stationary Cleaning Robot"
      html="<ul>
  <li><strong>Cleaning components of large & medium sized photovoltaic power</strong></li>
  <li><strong>Adopts waterless cleaning technology</strong></li>
  <li><strong>Manual/Auto cleaning mode *Remote control possible over wireless network</strong></li>
  <li><strong>High speed rotation roller brush</strong></li>
  <li><strong>Self-powered without external power supply</strong></li>
  <li><strong>Dimension: 400(mm) x 215(mm)</strong></li>
  <li><strong>Weight: 45kg</strong></li>
</ul>"
      color="black--text"
    >
    </base-right-photo>
    <br>
    <br>
  </section>
</template>

<script>
  export default {
    name: 'SectionSolarRobots',
  }
</script>
